/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from 'react';
import {
  View,
  ScrollView,
  Image,
  StyleSheet,
  Text,
  Platform,
  TouchableOpacity,
} from 'react-native';
import {Card, Title, Button, useTheme} from 'react-native-paper';

const TroubleSigningIn = ({navigation}) => {
  const theme = useTheme();

  const navigateToPage = (page: string) => {
    navigation.navigate(page);
  };

  return (
    <ScrollView>
      <Card style={styles.card}>
        <View style={styles.logo}>
          {Platform.OS === 'web' ? (
            <TouchableOpacity
              activeOpacity={0.5}
              onPress={() => navigateToPage('Sign In')}>
              <Image
                style={styles.image}
                source={require('../assets/logo.png')}
              />
            </TouchableOpacity>
          ) : (
            <Image
              style={styles.image}
              source={require('../assets/logo.png')}
            />
          )}
        </View>
        <Card.Content>
          <Title style={styles.title}>Trouble Signing In?</Title>
        </Card.Content>
        <View style={styles.container}>
          {/* <Button
            style={styles.button}
            mode="contained"
            onPress={() => navigateToPage('Request Token')}>
            <Text>Request Token</Text>
          </Button> */}
          <Button
            testID="resetPasswordButton"
            style={styles.button}
            mode="contained"
            //onPress={() => navigateToPage('Reset Password')}>
            onPress={() => navigateToPage('Request Token')}>
            <Text>Reset Password</Text>
          </Button>
          <Button
            testID="retrieveUsernameButton"
            style={styles.button}
            mode="contained"
            onPress={() => navigateToPage('Retrieve Username')}>
            <Text>Retrieve Username</Text>
          </Button>
          <Button
            style={styles.button}
            mode="contained"
            onPress={() => navigateToPage('Sign Up')}>
            <Text>Create Account</Text>
          </Button>
          <Button
            style={styles.forgot}
            mode="contained"
            onPress={() => navigateToPage('Sign In')}
            icon="chevron-left">
            <Text>Back</Text>
          </Button>
        </View>
      </Card>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  bgcontainer: {
    backgroundColor: '#fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    textAlign: 'center',
    marginTop: '5%',
  },
  card: {
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 30,
    height: 'auto',
    width: '100%',
    minWidth: 350,
    maxWidth: 500,
    marginTop: 50,
    marginBottom: 30,
    padding: '5%',
    margin: 'auto',
  },
  container: {
    padding: 8,
  },
  helpersWrapper: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  wrapper: {
    flex: 1,
  },
  helper: {
    flexShrink: 1,
  },
  counterHelper: {
    textAlign: 'right',
  },
  inputContainerStyle: {
    margin: 8,
  },
  fontSize: {
    fontSize: 24,
  },
  textArea: {
    height: 50,
    marginLeft: 10,
  },
  button: {
    margin: 8,
    padding: 10,
  },
  forgot: {
    backgroundColor: 'transparent',
    alignItems: 'flex-start',
    marginLeft: 15,
    fontWeight: '400',
  },
  logo: {
    marginBottom: 10,
    zIndex: 999999,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 250,
    height: 100,
    position: 'relative',
    margin: 'auto',
  },
  image: {
    marginBottom: 10,
    zIndex: 999999,
    alignItems: 'center',
    justifyContent: 'center',
    width: 250,
    height: 100,
    marginTop: '15%',
  },
});

export default TroubleSigningIn;
