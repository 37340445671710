//import { DefaultTransition } from '@react-navigation/stack/lib/typescript/src/TransitionConfigs/TransitionPresets';
import * as React from 'react';
import {useState, useEffect, useCallback} from 'react';
import {
  StyleSheet,
  Text,
  View,
  Image,
  Linking,
  Platform,
  TouchableOpacity,
  ScrollView,
} from 'react-native';
// import {lookup} from 'zipcodes-nrviens';
import {environment} from './../environments/environment.prod';
import {
  TextInput,
  Checkbox,
  Card,
  Title,
  Paragraph,
  Button,
  IconButton,
  HelperText,
  useTheme,
} from 'react-native-paper';
import {Picker} from '@react-native-picker/picker';
import {DatePickerModal} from 'react-native-paper-dates';
import WebPicker from './WebPicker';
import 'intl';
import 'intl/locale-data/jsonp/en';
import {UserProfile, Registration} from '../models/Index';

const SignUp = ({route, navigation}) => {
  const {url} = route.params;
  const {
    colors: {accent, surface},
  } = useTheme();

  /**
   * Gets orginization entity ID form initla URL
   * @returns entityId
   */
  function getEntityId(): number {
    // console.log('URL is ', url);
    const myUrl = url.toLowerCase();
    const location = myUrl.search('/partner/');
    if (location === -1 || !location) {
      // console.log('Result is  ', -99);
      return -99;
    }
    const entityId = myUrl.substring(location, url.length).split('/');

    if (entityId[2].length > 5) {
      console.log('Found Result is  ', entityId[2].substring(0, 5));
      return entityId[2].substring(0, 5);
    } else {
      console.log('Found Result is  ', entityId[2]);
      return entityId[2];
    }
  }

  const getCookie = async (cname) => {
    const name = cname + '=';
    const ca = await document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  };

  const initialOrganization = getEntityId();
  //checkboxes
  const [selectedOrganization, setSelectedOrganization] =
    React.useState(initialOrganization);
  const [gender, setGender] = React.useState(-1);

  //textboxes
  const [password, setPassword] = useState('');
  const [birthday, setBirthday] = useState(new Date('3000-12-17T03:24:00'));
  const [originalBirthday, setOriginalBirthday] = useState(birthday);
  const [openDate, setOpenDate] = useState(false);
  // const onDismissSingle = React.useCallback(() => {
  //   const [openDate, setOpenDate] = useState(false);
  //   (false);
  // }, [setOpenDate]);
  const [email, setEmail] = useState('');
  const [primaryPhone, setPrimaryPhone] = useState('');
  const [secondaryPhone, setSecondaryPhone] = useState('');
  const [address, setAddress] = useState('');
  const [addressCont, setAddressCont] = useState('');
  const [fname, setName] = useState('');
  const [lastName, setLastName] = useState('');
  const [zipcode, SetZipcode] = useState('');
  const [username, SetUsername] = useState('');
  const [agreeToTos, setAgreeToTos] = useState(false);

  //error checking
  const [errorEmail, setErrorEmail] = useState('');
  const [errorPassword, setErrorPassword] = useState('');
  const [passwordMatch, setPasswordMatch] = useState('');
  const [usedUsername, setUsedUsername] = useState('');
  const [genericError, setGenericError] = useState('');
  const [success, setSuccess] = useState('');

  //mic
  const [state, setState] = useState('State');
  const [city, setCity] = useState('City');
  const [organizations, setorganizations] = useState([]);

  const tosURL = 'https://biolinked.org/terms';

  const navigateToPage = (page: string) => {
    navigation.navigate(page);
  };

  const onConfirmSingle = useCallback(
    (params) => {
      console.log(params);
      console.log(params.date);
      setOpenDate(false);
      setBirthday(params.date);
    },
    [setOpenDate, setBirthday],
  );

  const checkDonorID = async (data: Registration) => {
    const userProfile = data.UserProfile;

    if (userProfile.UserIdentity === '-1') {
      try {
        const response = await fetch(
          environment.ApiURL + '/ParticipantPortal/getOrganizations',
        );
        const json = await response.json();
        if (json) {
          if (
            json.filter((i) => i.EntityId === userProfile.EntityIdentity)
              .length !== 0
              ? json
                  .filter((i) => i.EntityId === userProfile.EntityIdentity)
                  .map((j) => j.VerifyDonor)
                  .reduce((k) => k)
              : false
          ) {
            navigation.navigate('Connect Donor ID', {
              token: data.Token,
              userProfile: userProfile,
              org: json
                .filter((i) => i.EntityId === userProfile.EntityIdentity)
                .reduce((j) => j),
            });
            return;
            // }
          } else {
            navigation.navigate('Medical Entities', {
              token: data.Token,
              userProfile: userProfile,
            });
          }
          // });
        }
      } catch (error) {
        console.error(error);
      } finally {
      }
    } else
      navigation.navigate('Medical Entities', {
        token: data.Token,
        userProfile: userProfile,
      });
  };

  const gotoTOS = () => {
    if (Platform.OS === 'web') {
      window.open(tosURL, '_blank');
    } else {
      Linking.canOpenURL(tosURL).then((supported) => {
        return Linking.openURL(tosURL);
      });
    }
  };

  //log complete registation with market info
  const registrationCompleteMarketLog = async (): Promise<string> => {
    // console.log('Text to be encrypted: ' + text);
    // const marketInfo = await getCookie('bioSPInfo-market');
    const registerID = await getCookie('bioSPInfo-register');
    let marketInfoData;
    // console.log('cookie retrieval: ', marketInfo);
    console.log('Register complete: ', registerID);
    // console.log('Register complete parse: ', JSON?.parse(registerID));
    console.log(registerID);
    // console.log('cookies: ', document.cookie);
    if (registerID.length === 0) {
      marketInfoData = '';
    } else {
      marketInfoData = registerID;
    }
    // let getting = browser.cookies.get(
    //   details, // object
    // );
    // console.log('Validate Session Storage: ', sessionLogin);

    console.log('current token to be validated: ', marketInfoData);
    let result;
    //"http://localhost:5000/ParticipantPortal/registrationCompleteMarketLog?ID=a02f572b-7eed-4ac4-8987-fb7cf9a00d28"
    await fetch(
      `${environment.ApiURL}/ParticipantPortal/registrationCompleteMarketLog?ID=${marketInfoData}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        // body: JSON.stringify({
        //   id: registerID,
        // }),
      },
    )
      .then((response) => response)
      .then(async (data) => {
        console.log('logRegisterClick REtrunrs: ', data);
        result = data;
      });
    return result;
  };

  const registerUser = async () => {
    // console.log((Platform.OS === 'web') ? birthday : birthday.toLocaleDateString('en-US'));
    let dataObj: string;

    if (Platform.OS === 'web') {
      dataObj =
        '{"Gender": ' +
        gender +
        ', "FirstName": "' +
        fname +
        '" , "LastName": "' +
        lastName +
        '", "Birthday": "' +
        birthday +
        '", "Email": "' +
        email +
        '", "HomePhone": "' +
        primaryPhone +
        '", "CellPhone": "' +
        secondaryPhone +
        '", "Address1": "' +
        address +
        '", "Address2": "' +
        addressCont +
        '", "Zip": "' +
        zipcode +
        '" , "UserName": "' +
        username +
        '" , "Password": "' +
        password +
        '" , "UserIdentity": "' +
        '' +
        '" , "EntityIdentity": "' +
        selectedOrganization +
        '" }';
    } else {
      dataObj =
        '{"Gender": ' +
        gender +
        ', "FirstName": "' +
        fname +
        '" , "LastName": "' +
        lastName +
        '", "Birthday": "' +
        birthday.toLocaleDateString('en-US') +
        '", "Email": "' +
        email +
        '", "HomePhone": "' +
        primaryPhone +
        '", "CellPhone": "' +
        secondaryPhone +
        '", "Address1": "' +
        address +
        '", "Address2": "' +
        addressCont +
        '", "Zip": "' +
        zipcode +
        '" , "UserName": "' +
        username +
        '" , "Password": "' +
        password +
        '" , "UserIdentity": "' +
        '' +
        '" , "EntityIdentity": "' +
        selectedOrganization +
        '" }';
    }

    try {
      await fetch(environment.ApiURL + '/auth/register', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        body: dataObj,
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.Success) {
            setGenericError('');
            setSuccess('Registering account');
            // log complete retgistration when market
            registrationCompleteMarketLog();
            checkDonorID(data);
            sendRegistartionEmail(data.UserProfile);
          } else {
            setSuccess('');
            setGenericError(data.ErrorMessage);
            // console.log('/auth/register data', data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } finally {
    }
  };

  const sendRegistartionEmail = async (userProfile: UserProfile) => {
    try {
      await fetch(environment.ApiURL + '/auth/sendemailstoparticipants', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        body: JSON.stringify(userProfile),
      });
    } catch (error) {
      console.error(error);
    } finally {
      // setLoading(false);
    }
  };

  const getOrganizations = async () => {
    try {
      const response = await fetch(
        environment.ApiURL + '/ParticipantPortal/getOrganizations',
      );
      const json = await response.json();
      setorganizations(json);
    } catch (error) {
      console.error(error);
    } finally {
      // setLoading(false);
    }
  };

  useEffect(() => {
    getOrganizations();
  }, []);

  const makeRequest = async () => {
    console.log('Inside Make Request');

    if (selectedOrganization === -2) {
      setGenericError('Select an organization');
      return;
    }

    if (errorEmail) {
      setErrorEmail('Your Email is invalid, change it before registering');
      return;
    }
    if (errorPassword) {
      setErrorPassword(
        'Your Password is invalid, change it before registering',
      );
      return;
    }
    if (passwordMatch) {
      setPasswordMatch(
        'Your Passwords do not match, change it before registering',
      );
      return;
    }

    if (usedUsername) {
      setUsedUsername(
        'Your username has already been registered, login if you have already made an account, or select a different username',
      );
      return;
    }

    if (!fname) {
      setGenericError('Enter a name');
      return;
    }

    if (selectedOrganization === -99) {
      setGenericError('Please select an organization');
      return;
    }

    if (!lastName) {
      setGenericError('Enter a last name');
      return;
    }

    if (birthday === originalBirthday) {
      setGenericError('Enter a Date of Birth, in the form MM-DD-YYYY');
      return;
    } else {
      //New
      // Age validation
      // Age validation
      const today = new Date();
      const dd = String(today.getDate()).padStart(2, '0');
      const mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
      const yyyy = today.getFullYear();

      const now = yyyy + '-' + mm + '-' + dd;
      // console.log('Bday: ',  birthday.toString(), 'Today: ', typeof now);

      const tempDate = now.split('-');

      const bday = birthday.toString().split('-');

      console.log('position 0: ', bday[0], ', ', tempDate[0]);
      console.log('position 1: ', bday[1], ', ', tempDate[1]);
      console.log('position 2: ', bday[2], ', ', tempDate[2]);

      //In conflicts, keep this
      console.log();

      console.log(
        'Years ',
        parseInt(tempDate[0]) - 18,
        ', ',
        parseInt(bday[0]),
      );

      console.log('Moths ', parseInt(tempDate[1]), ', ', parseInt(bday[1]));

      console.log('Days', parseInt(tempDate[2]), ', ', parseInt(bday[2]));

      if (parseInt(tempDate[0]) - 18 < parseInt(bday[0])) {
        console.log(
          'Not 18 or over by year',
          parseInt(tempDate[0]) - 18,
          ', ',
          parseInt(bday[0]),
        );
        setGenericError('Invalid age: You must be 18 years old or above: ');
        return;
      } else if (parseInt(tempDate[0]) - 18 === parseInt(bday[0])) {
        if (parseInt(tempDate[1]) < parseInt(bday[1])) {
          console.log(
            'Not 18 or over by month: ',
            parseInt(tempDate[1]),
            ', ',
            parseInt(bday[1]),
          );
          setGenericError('Invalid age: You must be 18 years old or above');
          return;
        }
        if (parseInt(tempDate[1]) === parseInt(bday[1])) {
          if (parseInt(tempDate[2]) < parseInt(bday[2])) {
            console.log(
              'Not 18 or over by day:  ',
              parseInt(tempDate[2]),
              ', ',
              parseInt(bday[2]),
            );
            setGenericError('Invalid age: You must be 18 years old or above');
            return;
          }
        }
      }
    }

    if (!primaryPhone) {
      console.log('Birthday: ', birthday);
      console.log('originalBirthday: ', originalBirthday);

      setGenericError('Enter a primary phone');
      return;
    }

    if (!address) {
      setGenericError('Enter an address');
      return;
    }

    //address cont

    if (!zipcode) {
      setGenericError('Enter a Zipcode');
      return;
    }

    if (!city) {
      setGenericError('Not a valid Zipcode');
      return;
    }

    if (!username) {
      setGenericError('Enter a username');
      return;
    }

    if (!password) {
      setGenericError('Enter a password');
      return;
    }

    if (!agreeToTos) {
      setGenericError(
        'You must agree to the Terms of Service to register an account',
      );
      return;
    }
    setGenericError('');
    console.log(genericError);

    registerUser();
  };

  const checkIfUsernameIsUsed = async (text: string) => {
    if (text.length > 0) {
      try {
        const response = await fetch(
          environment.ApiURL +
            '/ParticipantPortal/usernameavailable?userName=' +
            text,
        );
        const json = await response.json();
        if (json) {
          setUsedUsername('');
          SetUsername(text);
        } else setUsedUsername('Username is already in use');
      } catch (error) {
        console.error(error);
      } finally {
      }
    } else setUsedUsername('');
  };

  const checkIfEmailIsUsed = async (text: string) => {
    try {
      const response = await fetch(
        environment.ApiURL + '/ParticipantPortal/emailregistered?email=' + text,
      );
      const json = await response.json();
      if (json)
        //setUsedEmail('Email is already in use');
        setErrorEmail(
          'Your Email is already used, login or reset password, or use a differnet email',
        );
      else {
        setErrorEmail('');
        setEmail(text);
      }
    } catch (error) {
      console.error(error);
    } finally {
    }
  };

  const validateEmail = (text: string) => {
    setEmail(text);
    let reg = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w\w+)+$/;
    if (reg.test(text) === false) {
      setErrorEmail('Invalid Email');
    } else {
      setErrorEmail('');
      checkIfEmailIsUsed(text);
    }
  };

  const getZipInfo = async (number: string) => {
    if (number.length !== 5) {
      return;
    } else {
      try {
        const response = await fetch('https://ziptasticapi.com/' + number);
        const json = await response.json();
        if (json) {
          console.log(json);
          const cityName = json.city || '';
          setCity(cityName);
          const stateName = json.state || '';
          setState(stateName);
        }
      } catch (error) {
        console.error(error);
      }
    }
    SetZipcode(number);
  };

  const checkMatch = (confirmPassowrd: string) => {
    if (confirmPassowrd !== password)
      setPasswordMatch('Passwords do not match');
    else setPasswordMatch('');
  };

  const validatePassword = (password: string) => {
    let reg = /^(?=.{7,20}$)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*\W).*$/;
    if (reg.test(password) === true) setErrorPassword('');
    else setErrorPassword('Invalid password');
    setPassword(password);
  };
  const onDismissSingle = () => {
    setOpenDate(false);
  };
  // const OnConfirmSingle = (date) => {
  //   setOpenDate(false)
  //   setBirthday(date)
  // };

  return (
    <ScrollView style={{width: '100%'}}>
      <View style={styles.wrapper}>
        <Card style={styles.card}>
          <View style={styles.logo}>
            {Platform.OS === 'web' ? (
              <TouchableOpacity
                activeOpacity={0.5}
                onPress={() => navigateToPage('Sign In')}>
                <Image
                  style={styles.image}
                  source={require('../assets/logo.png')}
                />
              </TouchableOpacity>
            ) : (
              <Image
                style={styles.image}
                source={require('../assets/logo.png')}
              />
            )}
          </View>
          <Card.Content>
            <Title style={styles.title}>Sign Up</Title>
            <Paragraph
              style={{
                color: '#c9deea',
                textAlign: 'center',
                fontSize: 16,
                padding: 20,
              }}>
              Please complete the registration form to proceed.
            </Paragraph>
          </Card.Content>
          <View style={styles.container}>
            <View style={styles.referral}>
              <TouchableOpacity style={styles.android}>
                <Picker
                  style={styles.picker}
                  itemStyle={styles.pickerText}
                  selectedValue={selectedOrganization}
                  onValueChange={(itemValue, itemIndex) =>
                    setSelectedOrganization(itemValue)
                  }>
                  {/* the value of -99 is changed before submission to -1 if the user does not select an organization */}
                  <Picker.Item
                    label="Select Referring Organization"
                    value="-99"
                  />

                  {organizations.map((temp) => {
                    return (
                      <Picker.Item label={temp.Name} value={temp.EntityId} />
                    );
                  })}
                </Picker>
              </TouchableOpacity>
            </View>
            <View style={styles.referral}>
              <TouchableOpacity style={styles.android}>
                <Picker
                  selectedValue={gender}
                  itemStyle={styles.pickerText}
                  style={styles.picker}
                  label="Gender"
                  defaultValue={-2}
                  accessibilityLabel="Select Gender"
                  onValueChange={(itemValue, itemIndex) =>
                    setGender(itemValue)
                  }>
                  <Picker.Item label="Select Gender" value="-1" />
                  <Picker.Item label="Male" value="0" />
                  <Picker.Item label="Female" value="1" />
                  <Picker.Item label="Non-Binary" value="2" />
                </Picker>
              </TouchableOpacity>
            </View>
            <TextInput
              textAlign="left"
              mode="outlined"
              theme={{
                colors: {text: 'white', primary: accent, background: surface},
              }}
              style={styles.inputContainerStyle}
              label="First Name"
              onChangeText={(text) => setName(text)}
            />
            <TextInput
              textAlign="left"
              mode="outlined"
              theme={{
                colors: {text: 'white', primary: accent, background: surface},
              }}
              style={styles.inputContainerStyle}
              label="Last Name"
              onChangeText={(text) => setLastName(text)}
            />
            {Platform.OS === 'web' ? (
              <View style={styles.webPickerContainer}>
                <Text style={styles.webPickerText}>
                  {' '}
                  Date of Birth
                  <WebPicker
                    style={styles.webPicker}
                    value={birthday}
                    onChange={setBirthday}
                  />
                </Text>
              </View>
            ) : (
              <View style={styles.mobPickerContainer}>
                <Text style={styles.mobPickerText}>
                  Date of Birth {birthday.toLocaleDateString('en-US')}
                </Text>
                <IconButton
                  label={'Date of Birth'}
                  theme={{
                    colors: {
                      text: 'white',
                      primary: accent,
                      background: surface,
                    },
                  }}
                  color={'white'}
                  style={styles.mobDatePicker}
                  icon="calendar"
                  onPress={() => setOpenDate(true)}
                  uppercase={false}
                  mode="outlined"
                />
                <DatePickerModal
                  // locale={'en'} optional, default: automatic
                  mode="single"
                  visible={openDate}
                  onDismiss={onDismissSingle}
                  date={birthday}
                  onConfirm={onConfirmSingle}
                />
              </View>
            )}

            <TextInput
              textAlign="left"
              mode="outlined"
              theme={{
                colors: {text: 'white', primary: accent, background: surface},
              }}
              style={styles.inputContainerStyle}
              label="Enter Email"
              // value={email}
              keyboardType="email-address"
              onChangeText={(text) => validateEmail(text)}
              error={errorEmail.length !== 0}
            />
            {errorEmail.length !== 0 && (
              <HelperText type="error" style={styles.error} visible={true}>
                {errorEmail}
              </HelperText>
            )}

            <TextInput
              textAlign="left"
              mode="outlined"
              theme={{
                colors: {text: 'white', primary: accent, background: surface},
              }}
              style={styles.inputContainerStyle}
              label="Primary Phone"
              // value={primaryPhone}
              keyboardType="phone-pad"
              onChangeText={(text) => setPrimaryPhone(text)}
            />
            <TextInput
              textAlign="left"
              mode="outlined"
              theme={{
                colors: {text: 'white', primary: accent, background: surface},
              }}
              style={styles.inputContainerStyle}
              label="Secondary Phone"
              keyboardType="phone-pad"
              onChangeText={(text) => setSecondaryPhone(text)}
            />
            <TextInput
              textAlign="left"
              mode="outlined"
              theme={{
                colors: {text: 'white', primary: accent, background: surface},
              }}
              style={styles.inputContainerStyle}
              label="Address"
              onChangeText={(text) => setAddress(text)}
            />
            <TextInput
              textAlign="left"
              mode="outlined"
              theme={{
                colors: {text: 'white', primary: accent, background: surface},
              }}
              style={styles.inputContainerStyle}
              label="Address Continued"
              onChangeText={(text) => setAddressCont(text)}
            />
            <TextInput
              textAlign="left"
              mode="outlined"
              theme={{
                colors: {text: 'white', primary: accent, background: surface},
              }}
              style={styles.inputContainerStyle}
              maxLength={5}
              keyboardType="phone-pad"
              placeholder={'Zipcode'}
              onChangeText={(number) => getZipInfo(number)}
            />
            <TextInput
              textAlign="left"
              mode="outlined"
              theme={{
                colors: {text: 'white', primary: accent, background: surface},
              }}
              style={styles.inputContainerStyle}
              value={city}
              disabled={true}
              placeholder={'City'}
            />
            <TextInput
              textAlign="left"
              mode="outlined"
              theme={{
                colors: {text: 'white', primary: accent, background: surface},
              }}
              style={styles.inputContainerStyle}
              value={state}
              disabled={true}
              placeholder={'State'}
            />
            <TextInput
              textAlign="left"
              mode="outlined"
              theme={{
                colors: {text: 'white', primary: accent, background: surface},
              }}
              style={styles.inputContainerStyle}
              label="Username"
              onChangeText={(text) => checkIfUsernameIsUsed(text)}
              error={usedUsername.length !== 0}
            />
            {usedUsername.length !== 0 && (
              <HelperText type="error" style={styles.error} visible={true}>
                {usedUsername}
              </HelperText>
            )}
            <TextInput
              textAlign="left"
              mode="outlined"
              theme={{
                colors: {text: 'white', primary: accent, background: surface},
              }}
              style={styles.inputContainerStyle}
              label="Password"
              secureTextEntry={true}
              onChangeText={(text) => validatePassword(text)}
              error={errorPassword.length !== 0}
            />
            <Text style={styles.password}>
              7 to 20 characters, one lower case, one upper case, one number,
              and one special (non-alphanumeric) character
            </Text>
            {errorPassword.length !== 0 && (
              <HelperText type="error" style={styles.error} visible={true}>
                {errorPassword}
              </HelperText>
            )}
            <TextInput
              textAlign="left"
              mode="outlined"
              theme={{
                colors: {text: 'white', primary: accent, background: surface},
              }}
              style={styles.inputContainerStyle}
              label="Confirm Password"
              secureTextEntry={true}
              onChangeText={(text) => checkMatch(text)}
              error={passwordMatch.length !== 0}
            />
            {passwordMatch.length !== 0 && (
              <HelperText type="error" style={styles.error} visible={true}>
                {passwordMatch}
              </HelperText>
            )}
            <View style={styles.disclaimer}>
              <View style={styles.box} />
              <Checkbox
                mode="android"
                status={agreeToTos ? 'checked' : 'unchecked'}
                onPress={() => {
                  setAgreeToTos(!agreeToTos);
                }}
              />
              <Text style={{color: 'white', paddingLeft: 10}}>
                I agree to the{' '}
              </Text>
              <Text style={{color: '#0a638c'}} onPress={() => gotoTOS()}>
                {' '}
                Privacy Policy.
              </Text>
            </View>
            <Button
              style={[styles.button, {backgroundColor: accent}]}
              mode="contained"
              onPress={() => makeRequest()}>
              <Text>Register</Text>
            </Button>
            <Button
              style={styles.forgot}
              mode="contained"
              onPress={() => navigateToPage('Sign In')}
              icon="chevron-left">
              <Text>Back</Text>
            </Button>

            {genericError?.length !== 0 && (
              <HelperText type="error" style={styles.error} visible={true}>
                {genericError}
              </HelperText>
            )}
            {success?.length !== 0 && (
              <HelperText type="error" style={styles.success} visible={true}>
                {success}
              </HelperText>
            )}
          </View>
        </Card>
      </View>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  mobPickerContainer: {
    borderWidth: 1,
    borderColor: 'white',
    borderRadius: 50,
    height: 64,
    margin: 8,
    color: 'white',
    paddingLeft: 15,
  },
  mobDatePicker: {
    borderWidth: 1,
    borderColor: 'transparent',
    borderRadius: 50,
    height: 64,
    margin: 8,
    color: 'white',
    ...Platform.select({
      ios: {
        marginLeft: '85%',
        marginTop: '-12%',
      },
      android: {
        marginLeft: '85%',
        marginTop: '-12%',
      },
    }),
  },
  mobPickerText: {
    display: 'flex',
    justifyContent: 'center',
    color: '#ffffff',
    fontSize: 16,
    paddingTop: 20,
  },
  webPickerContainer: {
    margin: 8,
    marginHorizontal: 20,
    color: 'white',
  },
  webPicker: {
    width: '100%',
    minWidth: 290,
    maxWidth: 1000,
    marginHorizontal: 20,
    borderColor: 'white',
    borderWidth: 1,
    borderRadius: 30,
    alignSelf: 'center',
    height: 64,
    color: 'white',
    backgroundColor: '#0f153a',
    margin: 8,
    paddingHorizontal: 10,
    marginLeft: -10,
  },
  webPickerText: {
    color: '#ffffff',
    fontSize: 14,
    paddingTop: 4,
    fontFamily: 'sans-serif',
  },
  wrapper: {
    flex: 1,
    alignItems: 'center',
  },
  forgot: {
    backgroundColor: 'transparent',
    fontWeight: '400',
    alignItems: 'flex-start',
  },
  bgcontainer: {
    backgroundColor: '#fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    textAlign: 'center',
    marginTop: '5%',
  },
  password: {
    color: '#c9deea',
    paddingHorizontal: 20,
  },
  box: {
    ...Platform.select({
      ios: {
        borderColor: 'white',
        borderWidth: 1,
        width: 20,
        height: 20,
        position: 'absolute',
        marginLeft: 23,
        alignItems: 'center',
        justifyContent: 'center',
      },
      web: {
        borderColor: 'transparent',
      },
    }),
  },
  card: {
    justifyContent: 'center',
    borderRadius: 30,
    width: '100%',
    minWidth: 350,
    maxWidth: 1000,
    margin: 'auto',
    marginBottom: 30,
    padding: '5%',
  },
  picker: {
    width: '100%',
    minWidth: 320,
    maxWidth: 1000,
    marginTop: 15,
    marginHorizontal: 20,
    borderColor: 'white',
    borderWidth: 1,
    borderRadius: 30,
    alignSelf: 'center',
    height: 64,
    color: 'white',
    backgroundColor: '#0f153a',
    margin: 8,
    paddingHorizontal: 10,
    ...Platform.select({
      android: {
        width: 280,
        marginTop: 15,
        borderColor: 'white',
        borderWidth: 1,
        borderRadius: 30,
        justifyContent: 'center',
        height: 50,
        color: 'white',
        margin: 8,
        paddingHorizontal: 10,
      },
    }),
  },
  pickerText: {
    height: 60,
    width: '100%',
    color: '#ffffff',
    fontSize: 16,
    ...Platform.select({
      android: {
        height: 40,
        paddingHorizontal: 10,
      },
    }),
  },
  container: {
    padding: 8,
  },
  helpersWrapper: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  helper: {
    flexShrink: 1,
  },
  counterHelper: {
    textAlign: 'right',
  },
  inputContainerStyle: {
    margin: 8,
  },
  fontSize: {
    fontSize: 24,
  },
  textArea: {
    height: 50,
    marginLeft: 10,
  },
  button: {
    margin: 8,
    padding: 10,
  },
  disclaimer: {
    display: 'flex',
    flexDirection: 'row',
    padding: '5%',
    alignItems: 'center',
  },
  referral: {
    display: 'flex',
    paddingLeft: 10,
    paddingRight: 10,
  },
  instructions: {
    display: 'flex',
    textAlign: 'center',
    margin: '0 auto',
    fontSize: 18,
  },
  description: {
    marginBottom: 15,
  },
  logo: {
    marginBottom: 10,
    zIndex: 999999,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 250,
    height: 100,
    position: 'relative',
    margin: 'auto',
  },
  image: {
    marginBottom: 10,
    zIndex: 999999,
    margin: 'auto',
    justifyContent: 'center',
    width: 250,
    height: 100,
    marginTop: '15%',
  },
  error: {
    fontSize: 14,
    color: '#FF0000',
    paddingLeft: 25,
    paddingTop: 4,
  },
  success: {
    fontSize: 14,
    color: '#c9deea',
    paddingLeft: 25,
    paddingTop: 4,
  },
  android: {
    ...Platform.select({
      android: {
        width: 320,
        marginTop: 15,
        borderColor: 'white',
        borderWidth: 1,
        borderRadius: 30,
        justifyContent: 'center',
        height: 50,
        color: 'white',
        margin: 8,
        paddingHorizontal: 10,
      },
    }),
  },
});

export default SignUp;
