/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable array-callback-return */

import * as React from 'react';
import {useState} from 'react';
import {StyleSheet, Text, View, Image, ScrollView} from 'react-native';
import {environment} from './../environments/environment.prod';
import {
  TextInput,
  Card,
  Title,
  Button,
  HelperText,
  useTheme,
} from 'react-native-paper';
import {inputReducer, State} from './../utils';
import {Registration} from '../models/Index';

const initialState: State = {
  text: '',
  customIconText: '',
  name: '',
  outlinedText: '',
  largeText: '',
  flatTextPassword: 'Password',
  outlinedLargeText: '',
  outlinedTextPassword: '',
  nameNoPadding: '',
  flatDenseText: '',
  flatDense: '',
  outlinedDenseText: '',
  outlinedDense: '',
  flatMultiline: '',
  flatTextArea: '',
  outlinedMultiline: '',
  outlinedTextArea: '',
  maxLengthName: '',
  flatTextSecureEntry: true,
  outlineTextSecureEntry: true,
  iconsColor: {
    flatLeftIcon: undefined,
    flatRightIcon: undefined,
    outlineLeftIcon: undefined,
    outlineRightIcon: undefined,
    customIcon: undefined,
  },
};
const marketingLog = async (
  location: string,
  medium: string,
  campaign: string,
): Promise<string> => {
  console.log('Market call: ', location, ', ', medium, ', ', campaign);
  let result;
  const values = {
    location: location,
    medium: medium,
    campaign: campaign,
  };
  const response = await fetch(
    environment.ApiURL + '/ParticipantPortal/logMarketing',
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        // Accept: 'application/json',
      },
      body: JSON.stringify({
        location: location,
        medium: medium,
        campaign: campaign,
      }),
    },
  )
    .then((response) => response)
    .then(async (data) => {
      console.log('logmarketingLog REtrunrs: ', data);
      result = data;
    });
  return result;
};
const SignIn = ({route, navigation}) => {
  const {url} = route.params;

  const checkMarketingURL = async () => {
    // console.log('URL is ', url);
    let myUrl = url.toLowerCase();
    console.log('My URL is: ', myUrl);
    if (myUrl == null) {
      // console.log('Result is  null', -99);
      //navigateToPage('Reset Password');
      return '';
    }
    //from = source ,Medium, Campaign
    const from = myUrl.search('from/');
    let medium = myUrl.search('medium/');
    let campaign = myUrl.search('campaign/');
    if (
      (from === -1 || !from) &&
      (medium === -1 || !medium) &&
      (campaign === -1 || !campaign)
    ) {
      console.log('Result is  no from');
      return '';
    } else {
      let fromValue = '';
      let mediumValue = '';
      let campaignValue = '';
      if (from !== -1) {
        fromValue = myUrl.substring(from, url.length).split('/')[1];
        myUrl = myUrl.replace('from/', '');
      }
      if (medium !== -1) {
        medium = myUrl.search('medium/');
        mediumValue = myUrl.substring(medium, url.length).split('/')[1];
        myUrl = myUrl.replace('medium/', '');
      }
      if (campaign !== -1) {
        campaign = myUrl.search('campaign/');
        campaignValue = myUrl.substring(campaign, url.length).split('/')[1];
        myUrl = myUrl.replace('campaign/', '');
      }
      console.log('from active');
      await marketingLog(fromValue, mediumValue, campaignValue);
      const marketValues = {
        from: fromValue,
        medium: mediumValue,
        campaign: campaignValue,
      };
      console.log('Market value: ', marketValues);
      setCookie('bioSPInfo-market', JSON.stringify(marketValues), 0.05);
      window.location.replace(myUrl);
    }
    // const entityId = myUrl.substring(location, url.length).split('/');

    // console.log('Found Result is  ', entityId[2]);
    // if (entityId[2] === 'digital') {
    //   console.log('Result is from and digital');
    //   //TODO: make API call for digital marketing
    //   await marketingLog('digital');
    //   //Reset URL to exclude from/
    //   window.location.replace(myUrl.replace('from/', ''));
    // } else if (entityId[2] === 'market') {
    //   console.log('Result is from and market');
    //   //TODO make API call for email marketing marketing
    //   await marketingLog('market');
    //   //Reset URL to exclude from/
    //   window.location.replace(myUrl.replace('from/', ''));
    // } else {
    //   console.log('Result is from!');
    //   return '';
    // }
  };

  const getToken = async () => {
    // console.log('URL is ', url);

    await checkMarketingURL();
    const myUrl = url;
    if (myUrl == null) {
      // console.log('Result is  null', -99);
      //navigateToPage('Reset Password');
      return '';
    }
    const location = myUrl.search('/reset-token/');
    if (location === -1 || !location) {
      // console.log('Result is  ', -99);
      return '';
    }
    const entityId = myUrl.substring(location, url.length).split('/');

    // console.log('Found Result is  ', entityId[2]);
    navigateToPage('Reset Password');
    return entityId[2];
  };

  const navigateToPage = (page: string) => {
    navigation.navigate(page);
  };
  const [password, setPassword] = useState('');
  const [loginToken, setLoginToken] = useState(getToken());
  const [username, setUsername] = useState('');
  const [genericError, setGenericError] = useState('');
  const [success, setSuccess] = useState('');
  const [state, dispatch] = React.useReducer(inputReducer, initialState);
  const {outlineTextSecureEntry} = state;
  const encryptionPassword = '1GBPw+#;Y>53OcPqrplUshzb28R5qas8';

  // const _isUsernameValid = (text: string) => /^[a-zA-Z]*$/.test(text)

  React.useEffect(() => {
    let validate = false;

    if (!validate) validateToken();
    return () => {
      validate = true;
    };
  }, []);

  const checkDonorID = async (data: Registration) => {
    // console.log('CheckDonorID: LoginData: ', localStorage['loginData']);
    // const data = localStorage['loginData'];

    // const sessionLogin = sessionStorage.getItem('loginInfo');
    // console.log('Session Storage: ', sessionLogin);
    const userProfile = data.UserProfile;

    if (userProfile.UserIdentity == '-1') {
      try {
        const response = await fetch(
          environment.ApiURL + '/ParticipantPortal/getOrganizations',
        );
        const json = await response.json();
        if (json) {
          //filter the Org based on mapping User's EntityIdentity and check Org's VerifyDonor field
          // json.map((temp) => {
          if (
            json.filter((i) => i.EntityId == userProfile.EntityIdentity)
              .length != 0
              ? json
                  .filter((i) => i.EntityId == userProfile.EntityIdentity)
                  .map((j) => j.VerifyDonor)
                  .reduce((k) => k)
              : false
          ) {
            navigation.navigate('Connect Donor ID', {
              token: data.Token,
              userProfile: userProfile,
              org: json
                .filter((i) => i.EntityId == userProfile.EntityIdentity)
                .reduce((j) => j),
            });
            return;
            // }
          } else {
            navigation.navigate('Medical Entities', {
              token: data.Token,
              userProfile: userProfile,
            });
          }
          // });
        }
      } catch (error) {
        console.error(error);
      } finally {
      }
    } else
      navigation.navigate('Medical Entities', {
        token: data.Token,
        userProfile: userProfile,
      });
  };

  const {
    colors: {accent, surface},
  } = useTheme();

  // const [encryptedData, setEncryptedData] = useState('');
  const encrypt = async (text: string): Promise<string> => {
    // console.log('Text to be encrypted: ' + text);
    let result = '';
    const response = await fetch(
      environment.ApiURL + '/auth/encrypt?loginInfoString=' + text,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        //body: JSON.stringify({data: text}),
      },
    )
      .then((response) => response.json())
      .then(async (data) => {
        // console.log('encrypt REtrunrs: ', data);
        result = data;
      });
    return result;
  };

  const decrypt = async (text: string): Promise<string> => {
    // console.log('decrypt text raw: ', text);
    // console.log('decrypt with add: ' + text);
    const encodedData = encodeURIComponent(text);
    let result = '';
    const response = await fetch(
      environment.ApiURL + `/auth/decrypt?loginInfoString=${encodedData}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        // body: text,
      },
    )
      .then((response) => response.json())
      .then((data) => {
        // console.log('decrypt REtrunrs: ', data);
        result = data;
      });
    return result;
  };

  const setCookie = (cname, cvalue, exdays) => {
    const d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    const expires = 'expires=' + d.toUTCString();
    document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
  };

  const getCookie = (cname) => {
    const name = cname + '=';
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  };

  const removeCookie = (name) => {
    // Set the expiration date to a past date
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/`;
  };

  const login = async () => {
    // const thing = [1, 2];
    // sessionStorage.setItem('item', thing);
    const test: string =
      '{"UserName": "' + username + ' " , "Password": "' + password + '"}';

    // console.log('login log: ', test);
    try {
      const response = await fetch(environment.ApiURL + '/auth/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        body: test,
      })
        .then((response) => response.json())
        .then(async (data) => {
          // console.log('Login string DATA: ', JSON.stringify(data));
          const encryptedData = await encrypt(JSON.stringify(data));
          // console.log('State for encrypted data: ', encryptedData);
          // await decrypt(encryptedData);

          // sessionStorage.setItem('loginInfo', JSON.stringify(data));
          // const d = new Date();
          // the 7 is for the days
          // d.setTime(d.getTime() + 7 * 24 * 60 * 60 * 1000);
          // const expires = 'expires=' + d.toUTCString();

          setCookie('bioSPInfo', encryptedData, 7);
          // document.cookie =
          //   'loginInfo= ' +
          //   encryptedData +
          //   '; expires= ' +
          //   expires +
          //   '; path=/bio-linked/subject/sign-in/';
          if (data.Token) {
            setGenericError('');
            // setSuccess("Logging In");
            // cache login data here
            // localStorage.setItem("myCat");
            // localStorage['loginData'] = data;

            // console.log('login data:', data);

            checkDonorID(data);
          } else {
            setSuccess('');
            setGenericError(data.Message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
      // eslint-disable-next-line no-empty
    } finally {
    }
  };

  const validateToken = async () => {
    // removeCookie('loginInfo');
    // removeCookie('bioSPInfo');
    // const sessionLogin = sessionStorage.getItem('loginInfo');
    // console.log('session retrieval: ', sessionLogin);
    const loginInfo = await getCookie('bioSPInfo');
    console.log('cookie retrieval: ', loginInfo);
    console.log('cookies: ', document.cookie);
    if (loginInfo.length == 0) {
      return;
    }
    const decryptedInfo = await decrypt(loginInfo);
    console.log('Decrypted cookieL: ', decryptedInfo);
    // let getting = browser.cookies.get(
    //   details, // object
    // );
    // console.log('Validate Session Storage: ', sessionLogin);
    const data = JSON?.parse(decryptedInfo);
    console.log('current token to be validated: ', data?.Token);

    // const test: string =
    //   '{"UserName": "' + username + ' " , "Password": "' + password + '"}';

    // const test: string = '{"token": "' + data?.Token + '"}';
    // const test: string = '{"token": "' + data.Token + ' "}';
    // const payload: string = JSON.stringify({
    //   token: data?.Token,
    // });
    // console.log(
    //   'Body to be sent: ',
    //   environment.ApiURL + '/auth/validate?token=' + data?.Token,
    // );

    // const token = data?.Token;

    const request = new Request(
      environment.ApiURL + '/auth/validate?token=' + data?.Token,
      {
        method: 'POST',
        mode: 'cors',
        headers: new Headers({
          'Content-Type': 'application/json',
          Accept: 'application/json',
        }),
      },
    );

    // console.log('REquest: ', request);
    try {
      const response = await fetch(request)
        .then((response) => response.json())
        .then((validation) => {
          console.log('___Validate auth return:', validation);
          if (validation) {
            // move on from the login page
            checkDonorID(data);
          } else {
            // remove login info from session
            removeCookie('bioSPInfo');
          }
        })
        .catch((error) => {
          console.log(error);
        });
      // eslint-disable-next-line no-empty
    } finally {
    }
  };

  const logRegisterClick = async () => {
    console.log('Log register called!!');
    const marketInfo = await getCookie('bioSPInfo-market');
    let marketInfoData;
    console.log('cookie retrieval: ', marketInfo);
    console.log(marketInfo);
    console.log('cookies: ', document.cookie);
    if (marketInfo.length == 0) {
      marketInfoData = {
        from: '',
        medium: '',
        campaign: '',
      };
    } else {
      marketInfoData = JSON?.parse(marketInfo);
    }
    // let getting = browser.cookies.get(
    //   details, // object
    // );
    // console.log('Validate Session Storage: ', sessionLogin);

    console.log('current token to be validated: ', marketInfoData);
    let result;
    const response = await fetch(
      `${environment.ApiURL}/ParticipantPortal/logRegisterClick?Location=${marketInfoData.from}&Medium=${marketInfoData.medium}&Campaign=${marketInfoData.campaign}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        // body: JSON.stringify({
        //   location: marketInfoData.from,
        //   medium: marketInfoData.medium,
        //   campaign: marketInfoData.campaign,
        // }),
      },
    );
    // .then((res) => {
    //   console.log('register log response: ', res);
    //   return res;
    // })
    // .then(async (data) => {
    //   console.log('logRegisterClick REtrunrs: ', data);
    //   result = data;
    // });

    const registerID = await response.json();

    console.log('Register click result: ', registerID);

    setCookie('bioSPInfo-register', registerID, 0.05);
    // return await response.json();
  };

  return (
    <ScrollView>
      <View style={styles.bgcontainer}>
        <Card style={styles.card}>
          <View style={styles.logo}>
            <Image
              style={styles.image}
              source={require('../assets/logo.png')}
            />
          </View>
          <Card.Content>
            <Title style={styles.title}>Sign In</Title>
          </Card.Content>
          <View style={styles.container}>
            <View>
              <TextInput
                textAlign="left"
                mode="outlined"
                style={styles.inputContainerStyle}
                theme={{
                  colors: {text: 'white', primary: accent, background: surface},
                }}
                label="Username"
                accessibilityLabel="Username"
                value={username}
                autoCorrect={false}
                autoCapitalize="none"
                autoCompleteType="off"
                importantForAutofill="no"
                onChangeText={(text) => setUsername(text)}
              />
              <TextInput
                mode="outlined"
                style={styles.inputContainerStyle}
                theme={{
                  colors: {text: 'white', primary: accent, background: surface},
                }}
                label="Password"
                accessibilityLabel="Password"
                value={password}
                autoCompleteType="off"
                onChangeText={(text) => setPassword(text)}
                secureTextEntry={outlineTextSecureEntry}
                right={
                  <TextInput.Icon
                    name={outlineTextSecureEntry ? 'eye' : 'eye-off'}
                    onPress={() =>
                      dispatch({
                        type: 'outlineTextSecureEntry',
                        payload: !outlineTextSecureEntry,
                      })
                    }
                  />
                }
              />
            </View>
            <Button
              testID="toubleSignInButton"
              style={styles.forgot}
              mode="contained"
              onPress={() => navigateToPage('Trouble Signing In')}>
              <Text>Trouble Signing In?</Text>
            </Button>
            {genericError.length != 0 && (
              <HelperText type="error" style={styles.error} visible={true}>
                {genericError}
              </HelperText>
            )}
            {success.length != 0 && (
              <HelperText type="error" style={styles.success} visible={true}>
                {success}
              </HelperText>
            )}
            <Card.Actions>
              <Button
                testID="signUpButton"
                style={styles.button}
                mode="contained"
                onPress={async () => {
                  await logRegisterClick();
                  // console.log('REgistration click returned: ', result);
                  navigateToPage('Sign Up');
                }}>
                <Text>Register</Text>
              </Button>
              <Button
                testID="loginButton"
                style={styles.buttonAlt}
                mode="contained"
                onPress={() => login()}>
                <Text>Login</Text>
              </Button>
            </Card.Actions>
          </View>
        </Card>
      </View>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  divider: {
    flex: 1,
  },
  bgcontainer: {
    backgroundColor: '#fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    textAlign: 'center',
    marginTop: '5%',
  },
  card: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    borderRadius: 30,
    height: 'auto',
    width: '80%',
    minWidth: 350,
    maxWidth: 500,
    margin: 'auto',
    marginTop: 50,
    marginBottom: 30,
    padding: '5%',
  },
  container: {
    padding: 8,
  },
  helpersWrapper: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  wrapper: {
    flex: 1,
    alignItems: 'center',
  },
  helper: {
    flexShrink: 1,
  },
  counterHelper: {
    textAlign: 'right',
  },
  inputContainerStyle: {
    margin: 8,
    zIndex: 999,
  },
  fontSize: {
    fontSize: 24,
  },
  textArea: {
    height: 50,
    marginLeft: 10,
  },
  button: {
    flex: 1,
    margin: 8,
    padding: 10,
    marginTop: 10,
  },
  buttonAlt: {
    flex: 1,
    margin: 8,
    padding: 10,
    marginTop: 10,
    backgroundColor: '#f1955b',
  },
  forgot: {
    backgroundColor: 'transparent',
    alignItems: 'flex-end',
    marginLeft: 15,
    fontWeight: '400',
    textTransform: 'capitalize',
  },
  logo: {
    marginBottom: 10,
    zIndex: 999999,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 250,
    height: 100,
    position: 'relative',
    margin: 'auto',
  },
  error: {
    fontSize: 14,
    color: '#FF0000',
    paddingLeft: 25,
    paddingTop: 4,
  },
  success: {
    fontSize: 14,
    color: '#c9deea',
    paddingLeft: 25,
    paddingTop: 4,
  },
  image: {
    marginBottom: 10,
    zIndex: 999999,
    alignItems: 'center',
    justifyContent: 'center',
    width: 230,
    height: 100,
    marginTop: '15%',
    margin: 'auto',
  },
});

export default SignIn;
